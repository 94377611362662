import Backbone from 'backbone';

let EnemyCollection = Backbone.Collection.extend({
	initialize() {
		this.data = [
			{name: 'Goblin Pleb', champion: false, epic: false},
			{name: 'Orc Warrior', champion: false, epic: false},
			{name: 'Ogre', champion: false, epic: false},
			{name: 'Chimaera', champion: false, epic: false},
			{name: 'Forest Troll', champion: false, epic: false},
			{name: 'Ogre King', champion: true, epic: false},
			{name: 'Orc Warlord', champion: true, epic: false},
			{name: 'Basilisk', champion: false, epic: true},
			{name: 'Elder Dragon', champion: false, epic: true},
			{name: 'Daemon Prince', champion: false, epic: true},
		];
	},

	fetch() {
		return new Promise((resolve) => {
			this.reset(this.data);
			resolve(this.data);
		});
	}
});

export default EnemyCollection;