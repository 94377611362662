import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faMinusCircle,
	faMinusSquare,
	faPlusCircle,
	faPlusSquare,
	faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";
import TagSelect from "./TagSelect";
import HelpTooltip from "./HelpTooltip";

class EventAction extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showContent: true
		};
	}

	componentDidMount() {
		const challengeModelEventList = [
			'update',
			'reset',
			'change',
			'enemy:added',
			'enemy:removed',
			'enemy:changed',
			'item:added',
			'item:removed',
			'item:changed'
		];

		this.props.model.on('change', function() {
			this.forceUpdate();
		}, this);

		this.props.model.get('challenge').on(challengeModelEventList.join(' '), function() {
			this.forceUpdate();
		}, this);

		this.props.model.get('challenge').get('success').on(challengeModelEventList.join(' '), function() {
			this.forceUpdate();
		}, this);

		this.props.model.get('challenge').get('failure').on(challengeModelEventList.join(' '), function() {
			this.forceUpdate();
		}, this);
	}

	componentWillUnmount() {
		this.props.model.off(null, null, this);
		this.props.model.get('challenge').off(null, null, this);
		this.props.model.get('challenge').get('success').off(null, null, this);
		this.props.model.get('challenge').get('failure').off(null, null, this);
	}

	onRemoveEventAction(model) {
		if (this.props.onRemove) {
			this.props.onRemove(model);
		}
	}

	onChallengeChange(model, key, evt) {
		const value = evt.target.value || null;

		model.set(key, value);
	}

	onRemoveEnemy(model, enemy) {
		model.get('enemies').remove(enemy);

		this.props.model.get('challenge').trigger('enemy:removed');
	}

	onAddEnemy(model) {
		model.get('enemies').add({});

		this.props.model.get('challenge').trigger('enemy:added');
	}

	onRemoveItem(model, item) {
		model.get('items').remove(item);

		this.props.model.get('challenge').trigger('item:removed');
	}

	onAddItem(model) {
		model.get('items').add({});

		this.props.model.get('challenge').trigger('item:added');
	}

	static between(value, min, max) {
		value = parseInt(value, 10);

		if (value < min) value = min;
		else if (value > max) value = max;

		return value;
	}

	renderChallengeData(model) {
		switch(model.get('event_type')) {
			case 'COMBAT':
				return (
					<div className="col col-2">
						{this.renderChallengeEnemies(model)}
					</div>
				);
			case 'ITEM_GAIN':
				return (
					<div className="col col-2">
						{this.renderChallengeItems(model)}
					</div>
				);
			case 'INJURY':
				return (
					<div className="col col-2">
						<label>Injury</label>
						<div>
							<select defaultValue={model.get('injury')} onChange={(e) => model.set('injury', (e && e.target.value) ? e.target.value : null)}>
								<option value="">Random Injury</option>
								<optgroup label="Attribute Injuries">
									<option value="STRENGTH">Strength</option>
									<option value="DEXTERITY">Dexterity</option>
									<option value="FINESSE">Finesse</option>
									<option value="INTELLECT">Intellect</option>
									<option value="WISDOM">Wisdom</option>
									<option value="ENDURANCE">Endurance</option>
									<option value="VITALITY">Vitality</option>
									<option value="PERCEPTION">Perception</option>
									<option value="CHARISMA">Charisma</option>
								</optgroup>
							</select>
						</div>
					</div>
				);
			case 'DAMAGE':
				return [
					<div key={1} className="col col-4">
						<label>Amount</label>
						<div>
							<input defaultValue={model.get('damage')} type="number" onChange={(e) => model.set('damage', (e && e.target.value) ? parseInt(e.target.value, 10) || null : null)} />
						</div>
					</div>,
					<div key={2} className="col col-4">
						<label>Damage Type</label>
						<div>
							<select defaultValue={model.get('damage_type')} onChange={(e) => model.set('damage_type', (e && e.target.value) ? e.target.value : null)}>
								<option value="PHYSICAL">Physical</option>
								<option value="MAGICAL">Magical</option>
								<option value="ELEMENTAL">Elemental</option>
								<option value="SPIRITUAL">Spiritual</option>
							</select>
						</div>
					</div>
				];
			case 'EVENT':
				return (
					<div className="col col-2">
						<label>Event</label>
						<div>
							<TagSelect
								maxItems={1}
								onSelectionChange={(data) => {
									model.set('next_event', data && data.length ? data[0].id : null);
								}}
								format={(selectedItems) => selectedItems.map((item) => item.get('name')).join(', ')}
								collection={this.props.events}
							/>
						</div>
					</div>
				);
			default:
				return [
					<div key={1} className="col col-4" />,
					<div key={2} className="col col-4" />
				];
		}
	}

	renderChallengeItems(model) {
		return model.get('items').map((item, itemIdx) => {
			return (
				<div className="challenge-item-drop" key={item.cid}>
					<div className="col no-pad col-2">
						<label>
							Item
						</label>
						<div>
							<TagSelect
								maxItems={1}
								onSelectionChange={(data) => {
									if (data && data.length && data[0].get('name')) {
										item.set(data[0].attributes);
									} else {
										item.clear();
									}

									this.props.model.get('challenge').trigger('item:changed');
								}}
								populateWith={item.has('name') ? [item] : []}
								format={(selectedItems) => selectedItems.map((item) => item.get('name')).join(', ')}
								collection={this.props.items}
							/>
						</div>
					</div>
					<div className="col no-pad col-2">
						<label>Drop Chance (%)</label>
						<div style={{ position: 'relative', width: '95%' }}>
							<input type="number" placeholder="100" defaultValue={item.get('item_drop_chance')} onChange={(e) => {
								let value = (e && e.target.value) ? EventAction.between(e.target.value, 0, 100) : null;
								e.target.value = value;
								item.set('item_drop_chance', value);
							}} />
							{itemIdx ?
								<FontAwesomeIcon
									style={{ position: 'absolute', top: '0.75rem', right: '0px', fontSize: '1rem' }}
									className="remove"
									title="Remove item drop"
									onClick={this.onRemoveItem.bind(this, model, item)}
									icon={faMinusCircle}
								/> :
								<FontAwesomeIcon
									style={{ position: 'absolute', top: '0.75rem', right: '0px', fontSize: '1rem' }}
									className="add"
									title="Add item drop"
									onClick={this.onAddItem.bind(this, model)}
									icon={faPlusCircle}
								/>}
						</div>
					</div>
				</div>
			);
		});
	}

	renderChallengeEnemies(model) {
		return model.get('enemies').map((enemy, enemyIdx) => {
			return (
				<div className="challenge-enemy" key={enemy.cid}>
					<div className="col no-pad col-2">
						<label>
							Enemy
						</label>
						<div>
							<TagSelect
								maxItems={1}
								onSelectionChange={(data) => {
									if (data && data.length && data[0].get('name')) {
										enemy.set(data[0].attributes);
									} else {
										enemy.clear();
									}

									this.props.model.get('challenge').trigger('enemy:changed');
								}}
								populateWith={enemy.has('name') ? [enemy] : []}
								format={(selectedItems) => selectedItems.map((item) => item.get('name')).join(', ')}
								collection={this.props.enemies}
							/>
						</div>
					</div>
					<div className="col no-pad col-2">
						<label>Modifier</label>
						<div>
							<div style={{ display: 'inline-block', width: '20%'}}>
								<select defaultValue={enemy.get('modifier_type') || null} onChange={(e) => enemy.set('modifier_type', (e && e.target.value) ? e.target.value : null)}>
									<option />
									<option value="+">+</option>
									<option value="-">-</option>
								</select>
							</div>
							<div style={{ display: 'inline-block', position: 'relative', width: '70%', marginLeft: '5%'}}>
								<input defaultValue={enemy.get('modifier_value')} onChange={(e) => enemy.set('modifier_value', (e && e.target.value) ? parseInt(e.target.value, 10) || null : null)} type="number" />
								{enemyIdx ?
									<FontAwesomeIcon
										style={{ position: 'absolute', top: '0.75rem', right: '0px', fontSize: '1rem' }}
										className="remove"
										title="Remove enemy"
										onClick={this.onRemoveEnemy.bind(this, model, enemy)}
										icon={faMinusCircle}
									/> :
									<FontAwesomeIcon
										style={{ position: 'absolute', top: '0.75rem', right: '0px', fontSize: '1rem' }}
										className="add"
										title="Add enemy"
										onClick={this.onAddEnemy.bind(this, model)}
										icon={faPlusCircle}
									/>}
							</div>
						</div>
					</div>
				</div>
			);
		});
	}

	renderActionDetails() {
		let texts = [];

		texts.push(!this.props.model.get('actor') ? 'Any Character' : this.props.model.get('actor') === 'PARTY' ? 'All Characters' : this.props.model.get('actor'));

		if (this.props.model.get('required_items')) {
			texts.push(this.props.model.get('required_items').length + ' required item(s)');
		}

		if (this.props.model.get('requirement_type') && (this.props.model.get('min') !== null || this.props.model.get('max') !== null)) {
			let requirementTypeText = this.props.model.get('requirement_type') + ' [';

			if (this.props.model.get('min') !== null) {
				requirementTypeText += 'Min. ' + this.props.model.get('min');
			}

			if (this.props.model.get('min') !== null && this.props.model.get('max') !== null) {
				requirementTypeText += ', ';
			}
			if (this.props.model.get('max') !== null) {
				requirementTypeText += 'Max. ' + this.props.model.get('max');
			}

			requirementTypeText += ']';

			texts.push(requirementTypeText);
		}

		if (this.props.model.get('challenge').get('event_type')) {
			let challengeEventType = 'Challenge: ' + this.props.model.get('challenge').get('event_type');

			if (this.props.model.get('challenge').get('event_type') === 'COMBAT') {
				if (this.props.model.get('challenge').get('enemies').models.filter((enemy) => !!enemy.get('name')).length > 0) {
					challengeEventType += ' vs ' + this.props.model.get('challenge').get('enemies')
						.filter((enemy) => enemy.get('name'))
						.map((enemy) => enemy.get('name'))
						.join(', ');
				}
			}

			texts.push(challengeEventType);
		}

		return texts.join(' - ');
	}

	render() {
		return (
			<div className="backdrop">
				<div className="section-head">
					<h3>
						Action {this.props.idx + 1} - {this.renderActionDetails()}
						<FontAwesomeIcon
							style={{ fontSize: '1.75rem' }}
							className="remove"
							title="Remove action"
							icon={faMinusCircle}
							onClick={this.onRemoveEventAction.bind(this, this.props.model)}
						/>
						<FontAwesomeIcon
							style={{ fontSize: '1.75rem' }}
							className="min-max"
							title="Minimize"
							icon={this.state.showContent ? faMinusSquare : faPlusSquare}
							onClick={() => this.setState({ showContent: !this.state.showContent })}
						/>
					</h3>
				</div>
				{this.state.showContent &&
				<section className="minor" key={this.props.idx}>
					<div className="row">
						<div className="col col-4">
							<label>
								Requirement Type
								<HelpTooltip
									text={'Some actions may require the player or the actor to meet a certain requirement.'}
									tooltipWidth={'200px'}
									icon={faQuestionCircle}
								/>
							</label>
							<div>
								<select defaultValue={this.props.model.get('requirement_type')} onChange={(e) => this.props.model.set('requirement_type',(e && e.target.value) ? e.target.value : null)}>
									<option value="">None</option>
									<optgroup label="Attributes">
										<option value="STRENGTH">Strength</option>
										<option value="DEXTERITY">Dexterity</option>
										<option value="FINESSE">Finesse</option>

										<option value="INTELLECT">Intellect</option>
										<option value="VITALITY">Vitality</option>
										<option value="ENDURANCE">Endurance</option>

										<option value="CHARISMA">Charisma</option>
										<option value="PERCEPTION">Perception</option>
										<option value="WISDOM">Wisdom</option>

										<option value="HEALTH">Health (%)</option>
										<option value="FOCUS">Focus (%)</option>
										<option value="MORALE">Morale (%)</option>
									</optgroup>
									<optgroup label="Coins">
										<option value="GOLD">Gold</option>
										<option value="SILVER">Silver</option>
										<option value="COPPER">Copper</option>
									</optgroup>
									<optgroup label="Party">
										<option value="SIZE">Size</option>
									</optgroup>
									<optgroup label="Other">
										<option disabled value="ITEM">Item</option>
										<option disabled value="QUEST">Quest</option>
									</optgroup>
									<optgroup label="Skills">
										<option disabled value="SKILL">????</option>
									</optgroup>
								</select>
							</div>
						</div>
						<div className="col col-4">
							<label>Value Range</label>
							<div style={{ display: 'inline-block', width: '40%'}}>
								<input defaultValue={this.props.model.get('min')} type="number" placeholder="Min." onChange={(e) => this.props.model.set('min', (e && e.target.value) ? parseInt(e.target.value, 10) || null : null)} />
							</div>
							<div style={{ display: 'inline-block', marginLeft: '5%', width: '40%'}}>
								<input defaultValue={this.props.model.get('max')} type="number" placeholder="Max." onChange={(e) => this.props.model.set('max', (e && e.target.value) ? parseInt(e.target.value, 10) : null)} />
							</div>
						</div>
						<div className="col col-4">
							<label>
								Actor Constraint
								<HelpTooltip
									text={'Which character or characters that should be included in the action.'}
									tooltipWidth={'250px'}
									icon={faQuestionCircle}
								/>
							</label>
							<div>
								<select defaultValue={this.props.model.get('actor')} onChange={(e) => this.props.model.set('actor',(e && e.target.value) ? e.target.value : null)}>
									<optgroup label="Meta Actors">
										<option value="">Any Character</option>
										<option value="PARTY">All Characters</option>
									</optgroup>
									<optgroup label="Playable Characters">
										<option value="MERCENARY">Mercenary</option>
										<option value="ABOMINATION">Abomination</option>
										<option value="SAGE">Sage</option>
										<option value="HUNTER">Hunter</option>
										<option value="CORSAIR">Corsair</option>
										<option value="RAIDER">Raider</option>
										<option value="BLADEMASTER">Blademaster</option>
										<option value="ALCHEMIST">Alchemist</option>
										<option value="WANDERER">Wanderer</option>
										<option value="SCION">Scion</option>
										<option value="TRICKSTER">Trickster</option>
										<option value="ORPHAN">Orphan</option>
										<option value="WARLOCK">Warlock</option>
										<option value="SHAMAN">Shaman</option>
										<option value="ACOLYTE">Acolyte</option>
									</optgroup>
								</select>
							</div>
						</div>
						<div className="col col-4">
							<label>
								Required Item
								<HelpTooltip
									text={'The actor must have one or more items in their possession.'}
									tooltipWidth={'250px'}
									icon={faQuestionCircle}
								/>
							</label>
							<div style={{ position: 'relative' }}>
								<TagSelect
									onSelectionChange={(data) => {
										this.props.model.set('required_items', data && data.length > 0 ? data : null);
									}}
									populateWith={this.props.model.get('required_items')}
									format={(selectedItems) => selectedItems.length + ' required item(s)'}
									collection={this.props.items}
								/>
							</div>
						</div>
					</div>
					<h3>
						Challenge
					</h3>
					<div className="challenge row">
						<div className="col col-4">
							<label>
								Event
								<HelpTooltip
									text={'If the action is selected, the actor must face a potential challenge in order to proceed.\n\nDo note that there are challenges that automatically succeed.'}
									tooltipWidth={'300px'}
									icon={faQuestionCircle}
								/>
							</label>
							<div>
								<select defaultValue={this.props.model.get('challenge').get('event_type')} onChange={this.onChallengeChange.bind(this, this.props.model.get('challenge'), 'event_type')}>
									<option value="">None</option>
									<option value="COMBAT">Combat</option>
									<option value="ROLL">Die Roll</option>
									<option value="PUZZLE">Puzzle</option>
									<option value="ITEM">Loot Drop</option>
									<option value="TREASURE">Treasure</option>
								</select>
							</div>
						</div>
						{this.renderChallengeData(this.props.model.get('challenge'))}
					</div>
					<div className="success row">
						<div className="col col-4">
							<label>
								Success Event
								<HelpTooltip
									text={'If the actor succeeds at completing the challenge, this is what happens next.'}
									tooltipWidth={'320px'}
									icon={faQuestionCircle}
								/>
							</label>
							<div>
								<select defaultValue={this.props.model.get('challenge').get('success').get('event_type')} onChange={this.onChallengeChange.bind(this, this.props.model.get('challenge').get('success'), 'event_type')}>
									<option value="">None</option>
									<option value="COMBAT">Combat</option>
									<option value="EVENT">Event</option>
									<option value="DEBUFF">Debuff</option>
									<option value="INJURY">Injury</option>
									<option value="DAMAGE">Damage</option>
									<option value="DEATH">Death</option>
									<option value="ITEM_GAIN">Item Gain</option>
									<option value="ITEM_LOSS">Item Loss</option>
									<option value="COIN_GAIN">Coin Loss</option>
									<option value="COIN_LOSS">Coin Gain</option>
								</select>
							</div>
						</div>
						{this.renderChallengeData(this.props.model.get('challenge').get('success'))}
						<div className="col col-4">
							<label>
								Final
								<HelpTooltip
									text={'Should the entire adventure end after this event?'}
									tooltipWidth={'300px'}
									icon={faQuestionCircle}
								/>
							</label>
							<div>
								<select onChange={(e) => this.props.model.get('challenge').get('success').set('final', e.target.value === '1')}>
									<option value="0">No</option>
									<option value="1">Yes</option>
								</select>
							</div>
						</div>
					</div>
					<div className="failure row">
						<div className="col col-4">
							<label>
								Failure Event
								<HelpTooltip
									text={'If the actor fails the challenge, this is what happens next.'}
									tooltipWidth={'200px'}
									icon={faQuestionCircle}
								/>
							</label>
							<div>
								<select defaultValue={this.props.model.get('challenge').get('failure').get('event_type')} onChange={this.onChallengeChange.bind(this, this.props.model.get('challenge').get('failure'), 'event_type')}>
									<option value="">None</option>
									<option value="COMBAT">Combat</option>
									<option value="EVENT">Event</option>
									<option value="DEBUFF">Debuff</option>
									<option value="INJURY">Injury</option>
									<option value="DAMAGE">Damage</option>
									<option value="DEATH">Death</option>
									<option value="ITEM_GAIN">Item Gain</option>
									<option value="ITEM_LOSS">Item Loss</option>
									<option value="COIN_GAIN">Coin Loss</option>
									<option value="COIN_LOSS">Coin Gain</option>
								</select>
							</div>
						</div>
						{this.renderChallengeData(this.props.model.get('challenge').get('failure'))}
						<div className="col col-4">
							<label>
								Final
								<HelpTooltip
									text={'Should the entire adventure end after this event?'}
									tooltipWidth={'300px'}
									icon={faQuestionCircle}
								/>
							</label>
							<div>
								<select defaultValue={this.props.model.get('challenge').get('failure') ? '1' : null} onChange={(e) => this.props.model.get('challenge').get('failure').set('final', e.target.value === '1')}>
									<option value="0">No</option>
									<option value="1">Yes</option>
								</select>
							</div>
						</div>
					</div>
				</section>}
			</div>
		);
	}
}

EventAction.propTypes = {
	onRemove: PropTypes.func,
	idx: PropTypes.number
};

export default EventAction;