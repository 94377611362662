import Backbone from "backbone";
import SuccessEventModel from "./SuccessEventModel";
import FailureEventModel from "./FailureEventModel";
import EnemyCollection from "../collections/EnemyCollection";

let ChallengeEventModel = Backbone.Model.extend({
	defaults: {
		requirement_type: null,
		event_type: null,
		min: null,
		max: null,
		actor: null,
		required_items: null,
		enemies: null,

		// Other event models that are linked to the challenge
		success: null,
		failure: null
	},

	initialize() {
		this.set('success', new SuccessEventModel());
		this.set('failure', new FailureEventModel());

		this.set('enemies', new EnemyCollection());
		this.get('enemies').reset({});

		// If at any time the enemies collection is emptied, add an empty enemy.
		this.get('enemies').on('remove update reset sync', function() {
			if (this.get('enemies') && this.get('enemies').length === 0) {
				this.get('enemies').add({});
			}
		}, this);

	}
});

export default ChallengeEventModel;