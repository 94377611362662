import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class HelpTooltip extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showTooltip: false
		};
	}

	renderTooltipText() {
		let chunks = this.props.text.split('\n');

		return chunks.map((chunk, idx) => {
			return <p key={idx}>{chunk}</p>;
		});
	}

	renderTooltip() {
		if (!this.state.showTooltip) {
			return null;
		}

		let classes = ['tooltip'];

		// If the X position of the container component is to the right of the center of the viewport, flip the tooltip to render to the left of the container.
		if (window.innerWidth / 2 < this.container.offsetLeft) {
			classes.push('reversed');
		}

		return (
			<div className={classes.join(' ')} style={{ width: this.props.tooltipWidth }}>
				{this.renderTooltipText()}
			</div>
		);
	}

	render() {
		return (
			<span
				ref={(container) => this.container = container}
				onMouseOver={() => this.setState({ showTooltip: true })}
				onMouseOut={() => this.setState({ showTooltip: false })}
				className="help-tooltip-container"
			>
				<FontAwesomeIcon
					style={this.props.style}
					icon={this.props.icon}
				/>
				{this.renderTooltip()}
			</span>
		);
	}
}

HelpTooltip.propTypes = {
	tooltipWidth: PropTypes.string,
	style: PropTypes.object
};

HelpTooltip.defaultProps = {
	style: { marginLeft: '10px', cursor: 'help'}
};

export default HelpTooltip;