import Backbone from 'backbone';
import EventModel from "../models/EventModel";

let EventCollection = Backbone.Collection.extend({
	model: EventModel,

	initialize() {
		this.data = [
			{id: 'PLACEHOLDER_ID1', name: 'The Wicked Witch'},
			{id: 'PLACEHOLDER_ID2', name: 'The Spooky Spider'},
			{id: 'PLACEHOLDER_ID3', name: 'The Bestest of Friends'},
			{id: 'PLACEHOLDER_ID4', name: 'One Mistake Too Many'},
			{id: 'PLACEHOLDER_ID5', name: 'Arthur Spamalot'},
			{id: 'PLACEHOLDER_ID6', name: 'The Wizard\'s Daughter'},
			{id: 'PLACEHOLDER_ID7', name: 'The Blacksmith\'s Puzzle'},
			{id: 'PLACEHOLDER_ID8', name: 'Greenskin Uprising'},
			{id: 'PLACEHOLDER_ID9', name: 'A Dream of Summer'},
			{id: 'PLACEHOLDER_ID0', name: 'A Regal Decree'}
		];
	},

	fetch() {
		return new Promise((resolve) => {
			this.reset(this.data);
			resolve(this.data);
		});
	}
});

export default EventCollection;