import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretSquareDown } from '@fortawesome/free-solid-svg-icons/faCaretSquareDown';
import { faCaretSquareUp } from '@fortawesome/free-solid-svg-icons/faCaretSquareUp';
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons/faCheckSquare";
import { faSquare } from "@fortawesome/free-solid-svg-icons/faSquare";
import {faCross} from "@fortawesome/free-solid-svg-icons";

class TagSelect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			focused: false,
			currentText: '',
			selectedItems: this.props.populateWith || [],
			showSelectedItems: false,
			showAllItems: false,
			dev: false
		};
		this.input = null;
	}

	componentDidMount() {

	}

	componentDidUpdate(oldProps, oldState) {
		if (this.input && !oldState.focused && this.state.focused) {
			if (this.state.dev) console.log('componentDidUpdate');
			this.input.focus();
		}
	}

	onFocus() {
		if (this.state.dev) console.log('onFocus');

		this.setState({
			focused: true
		});
	}

	onShiftFocus() {
		if (this.state.dev) console.log('onShiftFocus');

		this.setState({
			focused: true
		});
	}

	onBlur() {
		if (this.state.dev) console.log('onBlur');

		if (this.state.currentText) {
			return null;
		}

		this.setState({
			focused: false
		});
	}

	onChange(e) {
		if (this.state.dev) console.log('onChange');

		const newValue = e.target.value || '';

		this.setState({
			currentText: newValue,
			showAllItems: !!newValue,
			showSelectedItems: false
		});
	}

	onClick() {
		this.setState({
			focused: true
		});
	}

	onIconClick(e) {
		if (this.state.dev) console.log('onIconClick');

		e.stopPropagation();

		const showSelectedItems = !this.state.showSelectedItems;

		this.setState({
			showSelectedItems,
			showAllItems: false
		});
	}

	onHideItems(e) {
		if (this.state.dev) console.log('onHideItems');

		e.stopPropagation();

		this.setState({
			showSelectedItems: false,
			showAllItems: false,
			focused: false,
			currentText: ''
		});

		if (this.input) {
			this.input.value = '';
		}
	}

	onKeyDown(e) {
		// Handler for selecting the only filtered item when pressing TAB (if only one item is shown in the list) ...
		if (e && (e.keyCode === 9 || e.which === 9)) {
			const filteredItems = this.getFilteredItems();

			if (filteredItems.length !== 1 || !this.state.showAllItems) return;

			e.preventDefault();

			if (this.props.maxItems && this.props.maxItems === 1) {
				this.onSelectSingle(filteredItems[0], null);
			} else {
				this.onToggleSelection(filteredItems[0], null);
			}

			this.setState({ focused: false });
		}
	}

	onToggleSelection(item, e) {
		if (e) e.stopPropagation();

		if (this.state.dev) console.log('onToggleSelection');

		let arr = this.state.selectedItems,
			idx = arr.map(item => item.get('name')).indexOf(item.get('name'));

		this.input.value = '';

		if (idx >= 0) {
			arr.splice(idx, 1);
			this.setState({
				selectedItems: arr,
				focused: false
			}, () => {
				this.props.onSelectionChange(this.state.selectedItems);
			});
		} else {
			this.setState({
				selectedItems: [...arr, item],
				focused: false
			}, () => {
				this.props.onSelectionChange(this.state.selectedItems);
			});
		}
	}

	onSelectSingle(item, e) {
		if (e) e.stopPropagation();

		if (this.state.dev) console.log('onSelectSingle');

		let arr = this.state.selectedItems,
			idx = arr.map(item => item.get('name')).indexOf(item.get('name'));

		this.input.value = '';

		if (idx >= 0) {
			if (this.state.dev) console.log('deselecting');
			this.setState({
				selectedItems: [],
				showSelectedItems: false,
				showAllItems: false,
				focused: false,
				currentText: ''
			}, () => {
				if (this.state.dev) console.log(this.state.selectedItems);

				this.props.onSelectionChange(this.state.selectedItems);
			});
		} else {
			if (this.state.dev) console.log('selecting');
			this.setState({
				selectedItems: [item],
				showSelectedItems: false,
				showAllItems: false,
				focused: false,
				currentText: ''
			}, () => {
				if (this.state.dev) console.log(this.state.selectedItems);

				this.props.onSelectionChange(this.state.selectedItems);
			});
		}
	}

	filterEvents(haystack, string) {
		let arr = string ? string.toLowerCase().split(' ') : [];

		return arr.length === 0 || arr.every((stringPart) => {
			return haystack.toLowerCase().indexOf(stringPart) >= 0;
		});
	}

	getFilteredItems() {
		const maxResults = 10;
		return this.props.collection.models
			.filter((item) =>
				this.state.showAllItems ?
					this.filterEvents(item.get('name'), this.state.currentText) :
					this.state.showSelectedItems && this.state.selectedItems.length > 0 ?
						 this.state.selectedItems.map(item => item.get('name')).indexOf(item.get('name')) >= 0 : true)
			.slice(0, Math.min(this.props.collection.length, maxResults));
	}

	renderSelectedItemsBanner() {
		if (this.state.focused) {
			return null;
		}

		return [
			<input
				key={1}
				type="text"
				onFocus={this.onShiftFocus.bind(this)}
				className="tag-select-banner"
				onChange={x => x}
				value={this.props.format(this.state.selectedItems)}
			/>,
			<FontAwesomeIcon
				key={2}
				icon={this.state.showSelectedItems || this.state.showAllItems ? faCaretSquareUp : faCaretSquareDown}
				className="icon"
				onClick={this.onIconClick.bind(this)}
			/>
		];
	}

	renderItemList() {
		if (!this.state.showSelectedItems && !this.state.showAllItems) {
			return null;
		}

		return (
			<div className="selected-items">
				{this.renderItems()}
			</div>
		);
	}

	renderItems() {
		return this.getFilteredItems()
			.map((item, idx) => {
				const isSelectedItem = this.state.selectedItems.map(item => item.get('name')).indexOf(item.get('name')) >= 0;
				return (
					<div className="item" key={idx} onClick={this.props.maxItems === 1 ? this.onSelectSingle.bind(this, item) : this.onToggleSelection.bind(this, item)}>
						<FontAwesomeIcon
							icon={isSelectedItem ? faCheckSquare : faSquare }
						/>
						<span>{item.get('name')}</span>
					</div>
				);
			});
	}

	renderItemSelector() {
		return [
			<input
				key={1}
				style={{ display: this.state.focused ? 'inline-block' : 'none' }}
				type="text"
				defaultValue={this.state.currentText}
				onBlur={this.onBlur.bind(this)}
				onFocus={this.onFocus.bind(this)}
				onChange={this.onChange.bind(this)}
				onKeyDown={this.onKeyDown.bind(this)}
				ref={(input) => this.input = input}
			/>,
			this.input && this.input.value.length > 0 ? <FontAwesomeIcon
				key={2}
				icon={faCross}
				className="icon"
				onClick={this.onHideItems.bind(this)}
			/> : null
		];
	}

	render() {
		return (
			<div className="tag-select">
				{this.renderSelectedItemsBanner()}
				{this.renderItemSelector()}
				{this.renderItemList()}
			</div>
		);
	}
}

TagSelect.defaultProps = {
	populateWith: [],
	onSelectionChange: (value) => value,
	maxItems: null
};

export default TagSelect;