import Backbone from 'backbone';

let ItemCollection = Backbone.Collection.extend({
	initialize() {
		this.data = [
			{name: 'Dagger'},
			{name: 'Shortsword'},
			{name: 'Broadsword'},
			{name: 'Bastard Sword'},
			{name: 'Longsword'},
			{name: 'Two-handed Sword'},
			{name: 'Axe'},
			{name: 'Battle Axe'},
			{name: 'Mace'},
			{name: 'Maul'},
			{name: 'Buckler'},
			{name: 'Kite Shield'},
			{name: 'Pavise'},
			{name: 'Shortbow'},
			{name: 'Longbow'},
			{name: 'Crossbow'}
		];
	},

	fetch() {
		return new Promise((resolve) => {
			this.reset(this.data);
			resolve(this.data);
		});
	}
});

export default ItemCollection;