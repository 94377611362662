import Backbone from "backbone";
import EnemyCollection from "../collections/EnemyCollection";
import ItemCollection from "../collections/ItemCollection";

let SuccessEventModel = Backbone.Model.extend({
	defaults: {
		event_type: null,
		next_event: null,
		final: false,
		injury: null,
		damage: null,
		damage_type: null,
		enemies: null,
		items: null
	},

	initialize() {
		this.set('enemies', new EnemyCollection());
		this.set('items', new ItemCollection());

		this.get('enemies').reset({});
		this.get('items').reset({});

		// If at any time the enemies collection is emptied, add an empty enemy.
		this.get('enemies').on('remove update reset sync', function() {
			if (this.get('enemies') && this.get('enemies').length === 0) {
				this.get('enemies').add({});
			}
		}, this);

		this.get('items').on('remove update reset sync', function() {
			if (this.get('items') && this.get('items').length === 0) {
				this.get('items').add({});
			}
		}, this);
	}
});

export default SuccessEventModel;