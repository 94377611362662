import React from 'react';
import './css/App.css';

import TagSelect from './components/TagSelect';
import EventCollection from "./collections/EventCollection";
import ItemCollection from "./collections/ItemCollection";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import EventAction from "./components/EventAction";
import HelpTooltip from "./components/HelpTooltip";
import EnemyCollection from "./collections/EnemyCollection";
import EventModel from "./models/EventModel";
import EventActionModel from "./models/EventActionModel";

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            event: new EventModel(),
            events: new EventCollection(),
            items: new ItemCollection(),
            enemies: new EnemyCollection()
        };
    }

    componentDidMount() {
        this.state.events.fetch();
        this.state.items.fetch();
        this.state.enemies.fetch();

        this.state.event.get('eventActions').on('update reset', function() {
            this.forceUpdate();
        }, this);
    }

    componentWillUnmount() {
        this.state.event.get('eventActions').off(null, null, this);
    }

    onAddEventAction() {
        this.state.event.get('eventActions').add(new EventActionModel());
    }

    onRemoveEventAction(model) {
        this.state.event.get('eventActions').remove(model);
    }

    onReset() {
        let confirmed = window.confirm("Are you sure you want to reset the form and start over?");

        if (confirmed) {
            this.state.event.clear().set(this.state.event.defaults);
            this.state.event.get('eventActions').reset();
        }
    }

    render() {
        window.eventActions = this.state.event.get('eventActions');

        return (
            <div className="app-container">
                <div className="app">
                    <input type="text" className="event-search" placeholder="Find an event ..."  style={{ marginTop: '25px', width: '100%' }}/>
                    <h1>
                        {this.state.event.has('id') ? 'Event: ' + this.state.event.get('name') : 'New Event'}
                        <FontAwesomeIcon
                            className="new"
                            title={"Reset form and start over"}
                            onClick={this.onReset.bind(this)}
                            icon={faPlusCircle}
                        />
                    </h1>
                    <section className="backdrop">
                        <div className="row">
                            <div className="col col-4">
                                <label>Name</label>
                                <div>
                                    <input type="text" placeholder="Enter the name of the event"/>
                                </div>
                            </div>
                            <div className="col col-4">
                                <label>Max. Encounters per Adventure</label>
                                <div>
                                    <select>
                                        <option>Unlimited</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col col-4">
                                <label>
                                    Prerequisite Events
                                    <HelpTooltip
                                        icon={faQuestionCircle}
                                        text={'The party must have completed one or more other events before triggering this event.'}
                                        tooltipWidth={'250px'}
                                    />
                                </label>
                                <div>
                                    <TagSelect
                                        format={(selectedItems) => selectedItems.length + ' selected event(s)'}
                                        collection={this.state.events}
                                    />
                                </div>
                            </div>
                            <div className="col col-4">
                                <label>
                                    Prerequisite Items
                                    <HelpTooltip
                                        icon={faQuestionCircle}
                                        text={'The party must have one or more items in their possession before being able to trigger this event.'}
                                        tooltipWidth={'250px'}
                                    />
                                </label>
                                <div>
                                    <TagSelect
                                        format={(selectedItems) => selectedItems.length + ' selected item(s)'}
                                        collection={this.state.items}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <h1>
                        Actions
                        <FontAwesomeIcon
                            className="add"
                            title={"Add new action"}
                            onClick={this.onAddEventAction.bind(this)}
                            icon={faPlusCircle}
                        />
                    </h1>
                    {this.state.event.get('eventActions').length ? null : <section className="backdrop"><em>Click the blue plus icon above to add actions to the event ...</em></section>}
                    {this.state.event.get('eventActions').models.map((action, idx) => {
                        return (
                            <EventAction
                                key={action.cid}
                                idx={idx}
                                onRemove={this.onRemoveEventAction.bind(this)}
                                items={this.state.items}
                                enemies={this.state.enemies}
                                events={this.state.events}
                                model={action}
                            />
                        );
                    })}
                    <button className="submit green right">
                        {this.state.event.has('id') ? 'Save Event' : 'Create Event'}
                    </button>
                </div>
            </div>
        );
    }
}

export default App;
