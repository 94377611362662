import Backbone from "backbone";
import ChallengeEventModel from "./ChallengeEventModel";

let EventActionModel = Backbone.Model.extend({
	defaults: {
		challenge: null,
		actor: null,
		min: null,
		max: null,
		requirement_type: null,
		required_items: null // perhaps change to a collection so we can more easily update the react component when an item is removed
	},

	initialize() {
		this.set('challenge', new ChallengeEventModel());
	}
});

export default EventActionModel;